import request from "./request";

//新增配置信息
export function configAdd(data) {
    return request({
        url: '/project/config',
        method: 'post',
        data
    })
}

//修改配置信息
export function configPut(data) {
    return request({
        url: '/project/config',
        method: 'put',
        data
    })
}

//获取配置信息
export function getConfig(id) {
    return request({
        url: '/project/config/' + id,
        method: 'get'
    })
}

//删除配置信息
export function configDeltel(ids) {
    return request({
        url: '/project/config/' + ids,
        method: 'delete'
    })
}

//导出配置信息
export function configExport() {
    return request({
        url: '/project/config/export',
        method: 'post'
    })
}

//导入配置信息
export function configImportData(data) {
    return request({
        url: '/project/config/importData',
        method: 'post',
        data
    })
}

//获取配置信息列表
export function configList(query) {
    return request({
        url: '/project/config/list',
        params: query
    })
}
