<template>
    <div>
        <div class="bkColor">
            <headTitle :title="title" :routerPath="routerPath">
            </headTitle>
            <div class="projectTitle">
                <div class="T">{{ configDetails.name }}</div>
                <!-- <div style="font-size:14px">单位地址: {{ configDetails.address }}</div> -->
            </div>
            <div class="projectBody">
                <van-field v-model="configDetails.address" label="单位地址" readonly autosize type="textarea" />
                <van-field v-model="configDetails.contacts" label="联系人" readonly />
                <van-field v-model="configDetails.mobile" label="联系电话" readonly />
                <van-field v-model="configDetails.bank" label="开户行" readonly autosize type="textarea" />
                <van-field v-model="configDetails.bankNumber" label="行账号" readonly />
                <van-field v-model="configDetails.taxNumber" label="税号" readonly />
                <van-field v-model="configDetails.mainProducts" label="主营业务" readonly autosize type="textarea" />
                <van-field v-model="isBusinessLicense" label="有无营业执照" readonly />
                <van-field v-model="isPriceList" label="有无报价单" readonly />
            </div>
        </div>
        <div class="footContent">
            <div class="footButton" @click="delCell">
                <van-icon name="delete-o" size="16px" />
                删除
            </div>
            <div class="footButton" @click="modification">
                <van-icon name="todo-list-o" size="16px" />
                修改
            </div>
        </div>
        <van-popup v-model="isModify" :style="{ width: '80%', padding: '20px', maxHeight: '80%', overflow: 'auto' }" round>

            <van-form validate-first @failed="onFailed" @submit="submitForm" ref='form'>
                <van-field v-model="queryFrom.name" label="供应商单位" placeholder="请输入"
                    :rules="[{ required: true, message: '供应商单位不能为空' }]" required />
                <van-field v-model="queryFrom.address" label="单位地址" type="textarea" autosize placeholder="请输入"
                    :rules="[{ required: true, message: '单位地址不能为空' }]" required />
                <van-field v-model="queryFrom.contacts" label="联系人" placeholder="请输入" />
                <van-field v-model="queryFrom.mobile" label="联系电话" placeholder="请输入"
                    :rules="[{ required: true, message: '联系电话不能为空' }]" required />
                <van-field v-model="queryFrom.bank" label="开户行" type="textarea" autosize placeholder="请输入"
                    :rules="[{ required: true, message: '开户行不能为空' }]" required />
                <van-field v-model="queryFrom.bankNumber" label="行账号" placeholder="请输入"
                    :rules="[{ required: true, message: '行账号不能为空' }]" required />
                <van-field v-model="queryFrom.taxNumber" label="税号" placeholder="请输入"
                    :rules="[{ required: true, message: '税号不能为空' }]" required />
                <van-field v-model="queryFrom.mainProducts" type="textarea" autosize label="主营产品" placeholder="请输入" />
                <van-field name="radio" label="有无营业执照" required>
                    <template #input>
                        <van-radio-group v-model="queryFrom.businessLicense" direction="horizontal">
                            <van-radio :name="1">有</van-radio>
                            <van-radio :name="0">无</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field name="radio" label="有无报价单" required>
                    <template #input>
                        <van-radio-group v-model="queryFrom.priceList" direction="horizontal">
                            <van-radio :name="1">有</van-radio>
                            <van-radio :name="0">无</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-button round block type="info" native-type="submit" :disabled="isLoading" :loading="isLoading"
                    loading-text="加载中...">提交</van-button>
            </van-form>
        </van-popup>
    </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import { Field, Uploader, Button, Toast, Popup, Dialog, Icon, Radio, RadioGroup, DatetimePicker, Form } from 'vant'
import Vue from 'vue';
Vue.use(Field)
Vue.use(Uploader)
Vue.use(Button)
Vue.use(Toast)
Vue.use(Popup)
Vue.use(Dialog)
Vue.use(Icon)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(DatetimePicker);
Vue.use(Form)

import { timeChange } from '../units/units'
import { configDeltel, configPut } from '../api/config'
import { getConfigDetail } from '../api/configDetail'

import { ddShare } from "../units/dd";
export default {
    name: 'configDetails',
    components: {
        headTitle,
    },
    mounted() {
        this.id = this.$route.query.id
        this.isProject = this.$route.query.route ? true : false
        this.getConfigDetail()
    },
    data() {
        return {
            title: '供应商详情',
            id: '',
            configDetails: {
            },
            queryFrom: {

            },
            powerList: [],//value 1总经理 2财务 3行政
            routerPath: '/configList',//路由跳转记录
            belong: '',
            showC: false,
            currentDate: new Date(),
            isModify: false,
            isLoading: false,
            isBusinessLicense: '',
            isPriceList: '',

        }
    },
    methods: {
        getConfigDetail() {
            getConfigDetail(this.id).then(res => {
                this.configDetails = res.data.data
                this.isBusinessLicense = this.getStateText(this.configDetails.businessLicense)
                this.isPriceList = this.getStateText(this.configDetails.priceList)
                this.pageShare()
            })
        },
        getStateText(text) {
            switch (text) {
                case 0:
                    return "无"
                case 1:
                    return "有"
                default:
                    "--"
            }
        },
        getTime(time) {
            return timeChange(time)
        },

        pageShare() {
            let name = '供应商详情'
            ddShare('/configDetails?id=' + this.id, name)
        },
        delCell() {
            Dialog.confirm({
                message: `确定删除？`,
            }).then(() => {
                configDeltel(this.id).then(res => {
                    if (res.data.code == 200) {
                        Toast.success('操作成功')
                        this.$router.replace('/configList')
                    }
                })
            });
        },
        modification() {
            this.queryFrom = JSON.parse(JSON.stringify(this.configDetails))
            this.isModify = true
        },
        chooseTime() {
            this.showC = false
            this.queryFrom.configTime = this.currentDate
                .toLocaleString()
                .split(" ")[0];
            this.queryFrom.configTime = this.queryFrom.configTime.replace(/\//g, "-");
        },
        //校验不通过
        onFailed(errorInfo) {
            Toast.fail(errorInfo.errors[0].message)
        },
        submitForm() {
            this.isLoading = true
            configPut(this.queryFrom).then(res => {
                if (res.data.code == 200) {
                    this.isLoading = false
                    Toast.success("提交成功");
                    this.isModify = false
                    this.getConfigDetail()
                }
            }).catch(() => {
                this.isLoading = false
            })
        }
    }
}
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    background: #f6f6f6;
    padding-bottom: 9vh;
    position: relative;
    overflow: auto;
}

.projectTitle {
    width: 100vw;
    background: #fff;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 2vh;
    position: relative;

    .T {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1.5vh
    }

    .D {
        color: #F0A239;
        font-size: 1em;
    }

    .I {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        right: 70px;
    }
}

.projectBody {
    background: #fff;
}

/deep/ .van-cell {
    flex-wrap: wrap;
    // margin-bottom: 1vh;
}

/deep/ .van-cell__title {
    color: #797979;
}

/deep/ .van-field__label,
.van-cell__title {
    width: 100%;

}

/deep/ .van-cell::after {
    border-bottom: none;
}

.fileList {
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #797979;

    .title {
        text-align: left;
    }

    .fileImg {
        width: 80px;
        height: 80px;
    }

    .fileName {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.reviewedList {
    padding: 10px 16px;
    background: #fff;
    overflow: auto;
    position: relative;

    .A {
        top: 0px !important;
    }

    .T {
        top: 0px !important
    }

    .B {
        top: 45px !important;
    }

    .U {
        top: 20px !important
    }

    .F {
        height: 70px !important;
        min-height: 90px;
        margin: 0 !important;
    }
}

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 2vh;
}

.reviewed {
    position: relative;
    // height: 160px;
    min-height: 100px;
    // margin-bottom: 120px;
    padding: 10px 20px;
    box-sizing: border-box;
    // overflow: auto;

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        position: absolute;
        left: 20px;
    }

    .status {
        font-size: 13px;
        position: absolute;
        top: 10px;
        left: 75px;
    }

    .time {
        font-size: 13px;
        position: absolute;
        top: 10px;
        right: 20px;
        color: #B4B4B4;
    }

    .userName {
        position: absolute;
        top: 30px;
        left: 75px;
        font-size: 13px;
        font-weight: 600;
    }

    .remark {
        width: 60vw;
        // position: absolute;
        // left: 40px;
        max-height: 55px;
        font-size: 13px;
        background: #f6f6f6;
        text-align: left;
        padding: 5px;
        line-height: normal;
        word-wrap: break-word;
        overflow: auto;
    }

    .broder {
        width: 70vw;
        min-height: 50px;
        position: absolute;
        top: 55px;
        left: 45px;
        border-left: 6px solid #E4E4E4;
        padding-left: 25px
    }
}



.reviewed:nth-last-of-type(1) .broder {
    border: unset;
}

.projectFoot {
    margin-top: 2vh;
    width: 100vw;
    background: #fff;
    padding: 20px 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
}

#printer {
    width: 1391px;
    height: 100%;
    padding: 35px;
}

#printer p {
    font-size: 50px;
    font-weight: 600;
}

#printer table {
    table-layout: fixed !important;
    border-collapse: collapse !important;

}

#printer tr {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #000;
    border-left: none;

}

#printer tr:first-child {
    border-left: 1px solid #000;
}

#printer td {
    height: 90px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    font-size: 22px;
    word-break: break-all;
}

#printer td:first-child {
    border-left: 1px solid #000;
}

#printer .bold {
    font-weight: 600;
}

/deep/ .van-goods-action {
    background: #f6f6f6;
}

/deep/ .van-goods-action-icon {
    min-width: 18%;
    background: #f6f6f6;
    font-weight: 600;
}


/deep/ .van-button {
    border-radius: 6px;
}

.footContent {
    width: 100vw;
    height: 9vh;
    padding: 0 10px;
    box-sizing: border-box;
    position: fixed;
    // top: 0;
    // right: 0;
    bottom: 0;
    left: 0;
    background: #f6f6f6;
    display: flex;
    align-items: center;
}

.footButton {
    width: 60px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
</style>